<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-05 19:34:23
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" v-model="form" @submit="treeNodeSave">

    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    taskAdd,
    taskEdit
  } from "@/api/safe/algorithmManagement";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    ALGO_TASK_CATEGORY
  } from '../algorithmManagement/nodeDict.js'

  export default {
    components: {
      Template
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        isEdit: false,
        searchData: [],
        taskTypeOptions: [],
        pageType: '',

      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      // taskTypeOptions() {
      //   console.log('this.form.taskCategory==',this.form.taskCategory)
      //   return
      // },
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "任务名称",
              prop: "name",
              rules: [{
                required: true,
                message: "请输入任务名称",
                trigger: "blur"
              }, {
                max: 64,
                message: '任务名称不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "任务编码",
              prop: "code",
              rules: [{
                required: true,
                message: "请输入任务编码",
                trigger: "blur"
              }, {
                max: 64,
                message: '任务编码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "任务类型",
              prop: "taskType",
              type: 'select',
              dataType: 'string',
              props: {
                label: "name",
                value: "value",
              },
              dicData: ALGO_TASK_CATEGORY,
              event: {
                change: (val) => {
                  if (!val) return
                  if (val) {
                   this.taskTypeOptions = ALGO_TASK_CATEGORY.filter(item => item.value === val)[0].children
                  }
                },
              },

              rules: [{
                required: true,
                message: "请选择任务类型",
                trigger: "blur"
              }]
            },
            {
              label: "任务分类",
              prop: "taskCategory",
              type: 'select',
              dataType: 'string',
              props: {
                label: "name",
                value: "value",
              },
              dicData: this.taskTypeOptions,
              // dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
              rules: [{
                required: true,
                message: "请选择任务分类",
                trigger: "blur"
              }]
            },
            {
              label: "备注",
              prop: "remark",
              rules: [{
                message: "请输入备注",
                trigger: "blur"
              }, {
                max: 200,
                message: '备注不能超过200个字符',
                trigger: 'blur'
              }]
            },

          ],
        }
      },
    },

    methods: {
      init(type, data, treeData) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.pageType = type
        this.form.serverId = treeData.id
        if (type == 'add') {
          this.title = '新增任务'
        } else {
          this.form.id = data.id
          this.form = data
          this.title = '编辑任务'
        }
      },

      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      treeNodeSave(data) {
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            this.dialogLoading = true
            let url = this.pageType == 'add' ? taskAdd : taskEdit
            url(data).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.searchData = []
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

</style>
