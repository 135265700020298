var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.title,
            "before-close": _vm.handleCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "userName" } },
                        [
                          _c("el-input", {
                            on: { focus: _vm.selectPeople },
                            model: {
                              value: _vm.form.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userName", $$v)
                              },
                              expression: "form.userName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组织名称", prop: "orgName" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.orgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orgName", $$v)
                              },
                              expression: "form.orgName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "行程", prop: "tripState" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择行程状态" },
                              model: {
                                value: _vm.form.tripState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tripState", $$v)
                                },
                                expression: "form.tripState",
                              },
                            },
                            _vm._l(_vm.trip_state, function (item) {
                              return _c("el-option", {
                                key: item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "出发时间", prop: "startDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择出发时间",
                            },
                            model: {
                              value: _vm.form.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startDate", $$v)
                              },
                              expression: "form.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "出发地", prop: "startPlace" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择出发地" },
                            on: {
                              focus: function ($event) {
                                return _vm.handleRegionOPen("startPlace")
                              },
                            },
                            model: {
                              value: _vm.form.startPlace,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startPlace", $$v)
                              },
                              expression: "form.startPlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目的地", prop: "endPlace" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择目的地" },
                            on: {
                              focus: function ($event) {
                                return _vm.handleRegionOPen("endPlace")
                              },
                            },
                            model: {
                              value: _vm.form.endPlace,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "endPlace", $$v)
                              },
                              expression: "form.endPlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写备注",
                              autosize: { minRows: 2 },
                              "show-word-limit": "",
                              maxlength: "200",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.handleCancel },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSubmit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "region-dialog",
          attrs: {
            title: `请选择${_vm.regionTitle}`,
            visible: _vm.regionModel,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.regionModel = $event
            },
          },
        },
        [
          _c("avue-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading",
              },
            ],
            ref: "avueTree",
            attrs: { option: _vm.treeOption, data: _vm.treeData },
            on: { "node-click": _vm.nodeClick },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("selectPeopleByCurrentPrjoect", {
        ref: "selectPeopleByCurrentPrjoect",
        on: { callback: _vm.handleCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }