<template>
  <div class="main-box riskIdentification">
    <div class="flex-container">
      <nodeCommonTree class="introduceTree" treeTitle="节点配置" node-key="id" pageType='node' isLeafIcons isShowMore isShowdig risk :defaultExpandedKeys="defaultExpandedKeys" ref="commonTreeCore" @getTreeAdd="getTreeAdd" @getTreeAddChild="getTreeAddChild" @getTreeDelete="getTreeDelete" @getTreeEdit="getTreeEdit" :treeData="corePersonnelTreeData" :defaultProps="defaultProps" @getNodeClick="TreeNodeClick"
        @getTreeDrag="getTreeDragClick" :showCheckbox="false" />
      <div class="table-box">
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="activeTab=='node'">
          <el-tab-pane label="基础信息" name="base">
            <nodeBase :selectTree="selectTree"></nodeBase>
          </el-tab-pane>
          <!-- <el-tab-pane label="摄像头" name="task">
            <nodeCamera></nodeCamera>
          </el-tab-pane> -->
        </el-tabs>
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="activeTab=='server'||activeTab=='ai_box'">
          <el-tab-pane label="基础信息" name="base">
            <boxBase :selectTree="selectTree"></boxBase>
          </el-tab-pane>
          <el-tab-pane label="算法模型管理" name="model">
            <boxAlgorithmModel :selectTree="selectTree"></boxAlgorithmModel>
          </el-tab-pane>
          <el-tab-pane label="算法任务" name="task">
            <boxAlgorithmTask :selectTree="selectTree"></boxAlgorithmTask>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <addNodeTree ref="addNodeTree"></addNodeTree>
    <addBoxTree ref="addBoxTree"></addBoxTree>
    <addServerTree ref="addServerTree"></addServerTree>
    <nodeMove ref="nodeMove" @callback="typeTree"></nodeMove>
  </div>
</template>
<script>
  import nodeCommonTree from "./nodeCommonTree";
  import Template from "@/views/message/template/list.vue";
  import * as API from "@/api/safetyTtandard/riskIdentification";
  import {
    exportBlob
  } from "@/api/common";
  import {
    downloadXls
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    dictionaryBiz
  } from "@/api/reportTasks";

  import {
    getTree,
    remove,
    add
  } from "@/api/safe/algorithmManagement";
  import box from "./box/base";
  import addNodeTree from "./addNodeTree"
  import addBoxTree from "./addBoxTree"
  import nodeBase from "./node/base";
  import addServerTree from "./addServerTree"
  import nodeCamera from "./node/camera";
  import boxBase from "./box/base";
  import boxAlgorithmTask from "../algorithmTask/list";
  import boxAlgorithmModel from "./box/agorithmModelManagement/list";
  import nodeMove from "./nodeMove"

  export default {
    name: "index",
    components: {
      Template,
      GridLayout,
      nodeCommonTree,
      box,
      addNodeTree,
      addBoxTree,
      addServerTree,
      nodeBase,
      nodeCamera,
      boxBase,
      boxAlgorithmTask,
      boxAlgorithmModel,
      nodeMove
    },

    data() {
      return {
        activeTab: 'node',
        activeName: 'base',
        selectTree: undefined,
        jcxflForm: {
          parentId: '',
          isObj: 0,
        },
        jcxflModel: false,
        TableLoading: false,
        tableData: [],
        treeData: [],
        query: {},
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        defaultProps: {
          children: "children",
          value: 'id',
          label: "name",
        },
        corePersonnelTreeData: [],
        defaultExpandedKeys: [],
      }
    },
    mounted() {
      this.typeTree();
    },
    methods: {
      handleClick(tab) {
        this.activeName = tab.name
      },

      handleSubmit(form, done) {
        let data = {
          ...form,
          parentId: this.jcxflForm.parentId || -1,
        };
        let URL = form.isObj == 0 ? API.submit : API.strisksourceSubmit
        URL(data)
          .then((res) => {
            this.jcxflModel = false;
            this.$message.success(res.data.msg);
            this.typeTree();
          })
          .catch((err) => {
            done();
          });
      },

      getTreeAdd(row) {
        this.$refs.addNodeTree.init("addNode", row, 'node');
      },
      getTreeAddChild(node, type) {
        if (type == 'node') {
          this.$refs.addNodeTree.init("add", node, type);
        } else {
          this.$refs.addBoxTree.init("add", node, type);
        }

      },
      getTreeDragClick(node){
        this.$refs.nodeMove.init(node.data);
      },
      getTreeDelete(data) {
        this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: "warning",
        })
        let removeData = {
          id: data.id,
          type: data.type
        }
        remove(removeData).then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
      },

      getTreeEdit(row) {
        this.jcxflForm = {
          ...row,
          isObj: row.isSource ? 1 : 0,
        };
        const data = JSON.parse(JSON.stringify(this.corePersonnelTreeData));
        this.treeData = this.getParentData(data)
        this.jcxflModel = true;
      },
      getParentData(data, id) {
        data.forEach((val, index) => {
          if (val.id == this.jcxflForm.id) {
            data.splice(index, 1);
          } else if (val.children) {
            this.getParentData(val.children, this.jcxflForm.id);
          }
        });
        return data
      },

      TreeNodeClick(data) {
        this.selectTree = data
        this.activeTab = data.type
        this.activeName = 'base'
      },

      typeTree() {
        this.$refs.commonTreeCore.nodeData = {}
        this.selectTree = {}
        this.defaultExpandedKeys = []
        getTree({
          type: "server"
        }).then((res) => {
          this.corePersonnelTreeData = res.data.data;
          if (res.data.data.length != 0) {
            this.selectTree = res.data.data[0] || {};
            if (!this.selectTree.id) return;
            this.defaultExpandedKeys = [this.selectTree.id];
            this.$nextTick(() => {
              this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                this.selectTree.id
              );
              this.$refs.commonTreeCore.nodeData = this.selectTree;
              this.TreeNodeClick(this.selectTree);
            });
          }else{
            this.addFirstNode()
          }
        });
      },
      addFirstNode(){
        const queryData={
          name:'集团',
          code:'001',
          parentId: "0"
        }
        add(queryData).then(res => {

        })
      }
    },
  }

</script>
<style scoped lang="scss">
  .riskIdentification {
    background-color: #FFFFFF;

    .common_tree_handle .el-tree {
      height: calc(100vh - 278px) !important;
    }

    .table-box {
      width: calc(100% - 281px);


    }
  }

  ::v-deep .el-tabs {
    width: 100%;

    .el-tabs__nav-scroll {
      width: 100% !important;
    }
  }

  ::v-deep .el-tabs__nav {
    margin-left: 20px;
  }

  ::v-deep .introduceTable .avue-crud .el-table {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .introduceTree ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs--border-card {
    border-left: none;
    border-bottom: none;
  }

  ::v-deep .el-tabs__content {
    padding: 0 !important;
  }

</style>
