var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shuttleBackBox flex-container" },
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              treeData: _vm.organizationPersonnelTreeData,
              isShowdig: _vm.formType != "view" && _vm.isIndex ? true : false,
              defaultProps: _vm.organizationPersonnelDefaultProps,
              showCheckbox: false,
              "node-key": "id",
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.handleNode,
            },
          }),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c(
                "div",
                { staticClass: "flex-container flex-jus-sp header-box" },
                _vm._l(_vm.jsonList, function (item, index) {
                  return _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      attrs: {
                        "body-style": {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text item" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                      _c("div", { staticClass: "text item margin-t-10" }, [
                        _vm._v(_vm._s(_vm.countObj[item.key])),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _c("head-layout", {
                attrs: {
                  showIcon: false,
                  "head-title": "人员管理",
                  "head-btn-options": _vm.HeadBtnOptions,
                },
                on: {
                  "head-quot": _vm.handleSelect,
                  "head-import": _vm.headImport,
                  "head-no-use": function ($event) {
                    return _vm.handleIsUse(1, "batch")
                  },
                  "head-use": function ($event) {
                    return _vm.handleIsUse(0, "batch")
                  },
                },
              }),
              _c("grid-head-layout", {
                ref: "organizationPersonnelGridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.organizationPersonnelGridHeadSearch,
                  "grid-head-empty": _vm.organizationPersonnelGridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "itemListGridNoPage",
                attrs: {
                  page: _vm.page,
                  "data-total": _vm.page.total,
                  "table-options": _vm.corePersonnelTableOption,
                  "table-data": _vm.organizationPersonnelTableData,
                  "table-loading": _vm.tableLoading,
                },
                on: {
                  "page-current-change": _vm.getPrjstructureuserList,
                  "page-size-change": _vm.getPrjstructureuserList,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "baseCode",
                      fn: function ({ row }) {
                        return [
                          _c("el-image", {
                            staticStyle: {
                              "padding-top": "10px",
                              width: "40px",
                            },
                            attrs: {
                              "preview-src-list": [_vm.formatter(row)],
                              src: _vm.formatter(row),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "isUsed",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                effect: "dark",
                                type: _vm.isUsedDict[row.isUsed],
                              },
                            },
                            [_vm._v(_vm._s(row.$isUsed))]
                          ),
                        ]
                      },
                    },
                    _vm.formType != "view"
                      ? {
                          key: "customBtn",
                          fn: function ({ row, $index }) {
                            return [
                              _c("sy-button", {
                                staticClass:
                                  "tableBorder flex-jus-c flex-wrap-w",
                                attrs: {
                                  row: row,
                                  index: $index,
                                  "button-list": _vm.btnFun(row),
                                },
                              }),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.deptShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.deptShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deptShow = $event
                },
              },
            },
            [
              _c("UserDeptMultipleDialog", {
                ref: "UserDeptMultipleDialog",
                attrs: {
                  closeCallBack: "",
                  treeParams: {
                    deptCategory: "2",
                  },
                  userDeptType: "all",
                },
                on: {
                  closeDia: function ($event) {
                    _vm.deptShow = false
                  },
                  "select-all-data": _vm.handleUserSelectConfirm,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("addOrganizationPersonnel", {
        ref: "addOrganizationPersonnel",
        on: { callback: _vm.getPrjstructureList },
      }),
      _vm.userShow
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "用户管理编辑",
                visible: _vm.userShow,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.userShow = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.userForm,
                    border: "",
                    stripe: "",
                    size: "medium",
                    height: 300,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      width: "150",
                      label: "人员名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deptName",
                      label: "组织名称",
                      width: "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("avue-input-tree", {
                                attrs: {
                                  placeholder: "请选择组织名称",
                                  props: { label: "title", value: "id" },
                                  type: "tree",
                                  dic: _vm.filterData,
                                },
                                model: {
                                  value: row.deptId,
                                  callback: function ($$v) {
                                    _vm.$set(row, "deptId", $$v)
                                  },
                                  expression: "row.deptId",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1178784820
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "角色", "header-align": "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              _vm.userListData,
                              function (item, index) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: index,
                                    on: {
                                      change: function ($event) {
                                        return _vm.checkData(scope, item)
                                      },
                                    },
                                    model: {
                                      value: scope.row["roleCheck" + index],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "roleCheck" + index,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row['roleCheck' + index]",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.roleName) + "\n            "
                                    ),
                                  ]
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      239505078
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "stPostId", label: "工种", width: "200" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("avue-input-tree", {
                                attrs: {
                                  parent: false,
                                  props: { label: "postName", value: "id" },
                                  clearable: "",
                                  placeholder: "请选择工种",
                                  dic: _vm.postList,
                                },
                                model: {
                                  value: row.stPostId,
                                  callback: function ($$v) {
                                    _vm.$set(row, "stPostId", $$v)
                                  },
                                  expression: "row.stPostId",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      233235447
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "avue-dialog__footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.userShow = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSaveRole()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "相关方人员导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "import" },
            [
              _vm.excelBox
                ? _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }