var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-container", [
        _c(
          "div",
          {
            staticClass: "formMain",
            staticStyle: { width: "100%", background: "#ffffff" },
          },
          [
            _c("head-layout", {
              attrs: {
                "head-title": "绿色矿山",
                showIcon: false,
                "head-btn-options": _vm.headBtnOptions,
              },
              on: { "head-save": _vm.headSave },
            }),
            _c(
              "div",
              { staticStyle: { padding: "12px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      model: _vm.dataForm,
                      "label-width": "110px",
                      disabled: _vm.formType == "view",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业名称", prop: "prjCode" } },
                              [
                                _c("avue-input-tree", {
                                  attrs: {
                                    props: _vm.organDicProps,
                                    checkStrictly: "",
                                    "default-expand-all": "",
                                    placeholder: "请填写备案单位 ",
                                    dic: _vm.organDic,
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.dataForm.contractId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "contractId", $$v)
                                    },
                                    expression: "dataForm.contractId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "项目名称", prop: "prjName" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: "",
                                    placeholder: "请输入项目名称",
                                  },
                                  model: {
                                    value: _vm.dataForm.prjName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "prjName", $$v)
                                    },
                                    expression: "dataForm.prjName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "矿石种类", prop: "oreType" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择矿石种类" },
                                    model: {
                                      value: _vm.dataForm.oreType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "oreType", $$v)
                                      },
                                      expression: "dataForm.oreType",
                                    },
                                  },
                                  _vm._l(_vm.oreTypeList, function (item) {
                                    return _c("el-option", {
                                      key: item.dictKey,
                                      attrs: {
                                        label: item.dictValue,
                                        value: item.dictKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开采方式",
                                  prop: "excavateType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择开采方式" },
                                    model: {
                                      value: _vm.dataForm.excavateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "excavateType",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.excavateType",
                                    },
                                  },
                                  _vm._l(_vm.excavateTypeList, function (item) {
                                    return _c("el-option", {
                                      key: item.dictKey,
                                      attrs: {
                                        label: item.dictValue,
                                        value: item.dictKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "评选级别",
                                  prop: "selectionLevel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择评选级别" },
                                    model: {
                                      value: _vm.dataForm.selectionLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "selectionLevel",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.selectionLevel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectionLevelList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "评选状态",
                                  prop: "selectionType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择评选状态" },
                                    model: {
                                      value: _vm.dataForm.selectionType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "selectionType",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.selectionType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectionTypeList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "评选完成时间",
                                  prop: "selectionYear",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "year",
                                    "value-format": "yyyy",
                                    placeholder: "选择评选完成时间",
                                  },
                                  model: {
                                    value: _vm.dataForm.selectionYear,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "selectionYear",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.selectionYear",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remark" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入备注",
                                    type: "textarea",
                                  },
                                  model: {
                                    value: _vm.dataForm.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "remark", $$v)
                                    },
                                    expression: "dataForm.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }