<template>
  <el-dialog title="相关方人员选择" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog"
    width="60%" class="qmDialog addThirduserQuote" @close="closeDialog">
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"></grid-head-layout>

    <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
      :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
      <template slot="birthday" slot-scope="{ row }">
        <span :style="itemObject(row)">
          {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
        </span>
      </template>
      <template #customBtn="{ row }">
        <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { fieldInfo } from "@/api/base/length.js";
import { getList } from "@/api/safe/thirduser";
import {
  prjthirduserSubmit
} from "@/api/safe/itemList";
import { getAgeFromIdCard } from "@/util/util";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "名称",
          prop: "userName",
          span: 4,
          placeholder: "姓名或身份证号",
        },
        {
          label: "手机号",
          prop: "phone",
          span: 4,
          placeholder: "手机号",
        },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "姓名",
            prop: "userName",
            minWidth: fieldInfo.name_person.length,
            align: fieldInfo.name_person.align,
            overHidden: true
          },
          {
            label: "身份证号码",
            prop: "idCoding",
            minWidth: fieldInfo.IdentificationNumber.length,
            align: fieldInfo.IdentificationNumber.align,
            overHidden: true
          },
          {
            label: "手机号",
            prop: "phone",
            minWidth: fieldInfo.phoneNumber.length,
            align: fieldInfo.phoneNumber.align,
            overHidden: true
          },
          {
            label: "性别",
            prop: "sex",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
            minWidth: fieldInfo.Words4.length,
            align: fieldInfo.Words4.align,
            overHidden: true
          },
          {
            label: "年龄",
            prop: "birthday",
            minWidth: fieldInfo.Words4.length,
            align: fieldInfo.Words4.align,
            overHidden: true,
            slot: true,
          },
          {
            label: "籍贯",
            prop: "domicile",
            minWidth: fieldInfo.name_regionAll.length,
            align: fieldInfo.name_regionAll.align,
            overHidden: true
          },
        ],
      },
      tableData: [],
      submitBtn: false,
      emptyBtn: false,
      prjId: "",
      companyId: ""
    };
  },
  methods: {
    itemObject(item){
          let age =  this.getAge(item.idCoding) || 0;
          if(age>=60){
            return {color:'red'};
          }else{
            return {color:'black'};
          }
    },
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    init(prjId, companyId) {
      this.prjId = prjId
      this.companyId = companyId
      this.showDialog = true;
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // params.excludeRrjId = this.prjId
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择公司
    rowSelect(row) {
      this.showDialog = false;
      let params = {}
      params.userName = row.userName
      params.idCoding = row.idCoding
      params.sex = row.sex
      params.birthday = row.birthday
      params.domicile = row.domicile
      params.post = row.post
      params.phone = row.phone
      params.isUsed = 2
      params.prjId = this.prjId
      params.companyId = this.companyId
      prjthirduserSubmit(params).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
          this.$emit("callback");
        }
      })
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.addThirduserQuote .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
