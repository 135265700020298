<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-05 09:29:35
-->
<template>
  <div>
    <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
      <avue-form :option="{ ...options,disabled:pageType == 'view' ? true : false,}" :upload-exceed="uploadExceed" :upload-error="uploadSized" :upload-delete="uploadDelete" :upload-after="uploadAfter" ref="addForm" v-model="form" @submit="treeNodeSave">
        <template slot="beVisitName">
          <el-input v-model="form.beVisitName" placeholder="请选择被访人" @focus="handleUserChange"></el-input>
        </template>
      </avue-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <userAllSelect ref="userAllSelect" @callBack="saveUser"></userAllSelect>
  </div>
</template>

<script>
  import {
    add
  } from "@/api/safe/visitorReservation";
  import {
    getList
  } from "@/api/safe/thirduser";
  import {
    getAgeFromIdCard,
    getBirthDate
  } from "@/util/util";
  import {
    PATTERN_ID_CARD
  } from "@/util/validate"
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    cateList
  } from "@/api/safetyTtandard/occupationConfig";
  import userAllSelect from "../gate/user-all-select";

  export default {
    components: {
      Template,
      userAllSelect
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',
        fileAppend: {
          label: '',
          prop: '',
          type: 'upload',
          props: {
            label: 'originalName',
            value: 'thumbnailLink'
          },
          dataType: 'object',
          listType: 'picture-card',
          accept: 'image/*',
          fileSize: 5000,
          span: 24,
          propsHttp: {
            res: 'data'
          },
          tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
          action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
        },
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "访客姓名",
              prop: "visitName",
              rules: [{
                required: true,
                message: "请输入访客姓名",
                trigger: "blur"
              }, {
                max: 64,
                message: '访客姓名不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "证件类型",
              prop: "idType",
              type: 'select',
              dataType: 'string',
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqVisitAppointment_idType",
              rules: [{
                required: true,
                message: "请选择证件类型",
                trigger: "blur"
              }]
            },
            {
              label: "证件号码",
              prop: "idNumber",
              rules: [{
                required: true,
                message: "请输入证件号码",
                trigger: "blur"
              }, {
                max: 64,
                message: '证件号码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "联系方式",
              prop: "contactWay",
              rules: [{
                  required: true,
                  message: "请输入联系方式",
                  trigger: "blur",
                },
                {
                  pattern: /^1[3456789]\d{9}$/,
                  message: "联系方式格式错误"
                },
              ],
            },
            {
              label: "访客单位",
              prop: "visitCompany",
              rules: [{
                required: true,
                message: "请输入访客单位",
                trigger: 'blur'
              }, {
                max: 64,
                message: '访客单位不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "被访人",
              prop: "beVisitName",
              formslot: true,
              rules: [{
                required: true,
                message: "请输入被访人",
                trigger: "blur"
              }, {
                max: 64,
                message: '被访人不能超过64个字符',
                trigger: 'blur'
              }]

            },
            {
              label: "访问事由",
              prop: "visitReason",
              rules: [{
                required: true,
                message: "请输入访问事项",
                trigger: "blur"
              }, {
                max: 64,
                message: '访问事由不能超过64个字符',
                trigger: 'blur'
              }]

            },
            {
              label: "预计开始日期",
              prop: "planBeginTime",
              type: "date",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              disabled: this.pageType == 'view',
              "picker-options": {
                disabledDate: (time) => {
                  return time.getTime() < Date.now() - 3600 * 1000 * 24;
                },
              },
              rules: [{
                required: true,
                message: "请输入预计开始日期",
                trigger: "blur"
              }]
            },
            {
              label: "预计结束日期",
              prop: "planEndTime",
              type: "date",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              disabled: this.pageType == 'view',
              "picker-options": {
                disabledDate: (time) => {
                  return time.getTime() < Date.now() - 3600 * 1000 * 24;
                },
              },
              rules: [{
                required: true,
                message: "请输入预计结束日期",
                trigger: "blur"
              }]
            },
            {
              limit: 1,
              label: '人员近照',
              prop: 'photo',
              type: 'upload',
              props: {
                label: 'originalName',
                value: 'thumbnailLink'
              },
              dataType: 'object',
              listType: 'picture-card',
              accept: 'image/*',
              fileSize: 5000,
              span: 24,
              propsHttp: {
                res: 'data'
              },
              tip: '①五官无遮挡：如刘海、长发，勿遮挡眉毛、脸颊。不可出现曝光不均匀（阴阳脸）\n' +
                '\n' +
                '②正脸大头照：不能用精修的证件照\n' +
                '\n' +
                '③背景干净：不可出现其他人脸，过度曝光的背景环境\n' +
                '\n' +
                ' ④表情正常\n' +
                '\n' +
                '⑤照片比例为4:3，最佳分辨率为600*600以',
              action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km',
              rules: [{
                required: true,
                message: "请上传人员近照",
                trigger: "change",
              }, ],
            },
            {
              label: '备注',
              prop: 'remarks',
              formslot: true,
              rules: [{
                max: 200,
                message: '备注不能超过200个字符',
                trigger: 'blur'
              }]
            },

          ],
        }
      },
    },

    methods: {
      init(type, data, id) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.form.prjId = id
        this.pageType = type
        this.form.id = ''
        this.form.beVisitUserId = ''
        if (type == 'edit') {
          this.title = '编辑访客'
          this.$set(this, 'form', {
            ...data,
            photo: data.photo && JSON.parse(data.photo)
          })
        } else if (type == 'view') {
          this.title = '查看访客'
          this.$set(this, 'form', {
            ...data,
            photo: data.photo && JSON.parse(data.photo)
          })
        } else {
          this.title = '新增访客'
        }

      },
      // 身份证校验
      validateIdCard(rule, value, callback) {
        if (value == '') {
          callback(new Error('请填写证件号码'));
        } else {
          let valid = PATTERN_ID_CARD.test(value)
          if (valid) {
            callback();
          } else {
            callback(new Error('请填写正确的证件号码'));
          }
        }

      },
      uploadAfter(res, done, loading, column) {
        this.form[column.prop].push({
          ...res,

        })
        this.$forceUpdate()
        loading()
      },
      uploadDelete(file, column) {
        this.form[column.prop].splice(file.uid, 1);
      },
      uploadSized(msg, column) {
        this.$message.error(`${column.label}${msg}`)
      },
      uploadExceed(limit, files, fileList, column) {
        this.$message.error(`${column.label}上传数量限制${limit}张，如果需要重新上传，请先删除之前的图片`)
      },
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      handleUserChange() {
        let userinfo = {
          userId: this.form.beVisitUserId,
          userTargetName: this.form.beVisitName
        }
        console.log('userinfo==',userinfo)
        this.$refs.userAllSelect.init(this.form.prjId, this.form.beVisitName ? userinfo : null, 'only')
      },
      saveUser(data) {
        this.form.beVisitUserId = data.userId
        this.form.beVisitName = data.userTargetName
        this.$forceUpdate()
      },
      treeNodeSave(data) {
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            this.dialogLoading = true
            let photoData = []
            if (data.photo && data.photo.length) {
              data.photo.filter((item) => {
                if (item) {
                  photoData.push(item)
                }
              });
            }
            const query = {
              ...data,
              photo: photoData.length ? JSON.stringify(photoData) : '',
            }
            add(query).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.form.photo = ''
        this.form.beVisitName = ''
        this.form.beVisitUserId = ''
        this.searchData = []
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

</style>
