var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.showPage,
          expression: "!showPage",
        },
      ],
      staticClass: "projectSet-container",
    },
    [
      _c("head-layout", {
        staticClass: "box-border-no",
        attrs: {
          "head-title": _vm.dataForm.prjName,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _vm.showPage
        ? _c(
            "div",
            { staticClass: "formContentBox shuttleBackBox" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { "tab-position": "left", type: "border-card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  false
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "corePersonnel" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-QCxunjian",
                              }),
                              _vm._v(" 项目角色授权"),
                            ]
                          ),
                          _vm.activeName == "corePersonnel"
                            ? _c(
                                "el-container",
                                [
                                  _c("CommonTree", {
                                    ref: "commonTreeCore",
                                    attrs: {
                                      treeTitle: "项目角色",
                                      searchTitle: _vm.searchTitle,
                                      treeData: _vm.corePersonnelTreeData,
                                      defaultProps: _vm.defaultProps,
                                      isShowdig: false,
                                      showCheckbox: false,
                                      treeExpand: false,
                                      "node-key": "id",
                                    },
                                    on: {
                                      getNodeClick:
                                        _vm.corePersonnelTreeNodeClick,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "table-box" },
                                    [
                                      _c("head-layout", {
                                        attrs: {
                                          showIcon: false,
                                          "head-title": "项目角色授权",
                                          "head-btn-options":
                                            _vm.corePersonnelHeadBtnOptions,
                                        },
                                        on: {
                                          "head-add": _vm.handleSelect,
                                          "head-no-use": function ($event) {
                                            return _vm.handleIsUse(1, "batch")
                                          },
                                          "head-use": function ($event) {
                                            return _vm.handleIsUse(0, "batch")
                                          },
                                        },
                                      }),
                                      _c("grid-head-layout", {
                                        ref: "corePersonnelGridHeadLayout",
                                        attrs: {
                                          "search-columns":
                                            _vm.corePersonnelSearchColumns,
                                        },
                                        on: {
                                          "grid-head-search":
                                            _vm.corePersonnelGridHeadSearch,
                                          "grid-head-empty":
                                            _vm.corePersonnelGridHeadEmpty,
                                        },
                                      }),
                                      _c("grid-layout", {
                                        ref: "gridLayOut",
                                        staticClass: "itemListGridNoPage",
                                        attrs: {
                                          "table-options":
                                            _vm.corePersonnelTableOption,
                                          "table-data":
                                            _vm.corePersonnelTableData,
                                        },
                                        on: {
                                          "gird-handle-select-click":
                                            _vm.selectionChange,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "birthday",
                                              fn: function ({ row }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        _vm.itemObject(row),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.getAge(
                                                              row.birthday
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            _vm.formType != "view"
                                              ? {
                                                  key: "customBtn",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            margin: "0 3px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleIsUse(
                                                                row.isUsed === 1
                                                                  ? 0
                                                                  : 1,
                                                                "only",
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.isUsed === 1
                                                                ? "启用"
                                                                : "停用"
                                                            ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            margin: "0 3px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.rowdel(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "删除\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.showTab || _vm.showTab == "2"
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "blacklistPersonnel" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-heimingdan",
                              }),
                              _vm._v(" 黑名单人员"),
                            ]
                          ),
                          _c("head-layout", {
                            attrs: {
                              "head-title": "黑名单人员",
                              showIcon: false,
                            },
                          }),
                          _vm.activeName == "blacklistPersonnel"
                            ? _c("grid-head-layout", {
                                ref: "blacklistPersonnelGridHeadLayout",
                                attrs: {
                                  "search-columns":
                                    _vm.blacklistPersonnelSearchColumns,
                                },
                                on: {
                                  "grid-head-search":
                                    _vm.blacklistPersonnelGridHeadSearch,
                                  "grid-head-empty":
                                    _vm.blacklistPersonnelGridHeadEmpty,
                                },
                              })
                            : _vm._e(),
                          _vm.activeName == "blacklistPersonnel"
                            ? _c("grid-layout", {
                                ref: "blacklistPersonnelGridLayOut",
                                staticClass: "itemListGridNoPage",
                                attrs: {
                                  "table-loading": _vm.tableLoading,
                                  "table-options":
                                    _vm.blacklistPersonnelTableOption,
                                  "table-data": _vm.blacklistPersonnelTableData,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "birthday",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "span",
                                            { style: _vm.itemObject(row) },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getAge(row.birthday)
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    _vm.formType != "view"
                                      ? {
                                          key: "customBtn",
                                          fn: function ({ row }) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    margin: "0 3px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeBlack(
                                                        row,
                                                        "personnel"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "移除黑名单\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        }
                                      : null,
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.showTab || _vm.showTab == "1"
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "projectArea" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-zhishiditu",
                              }),
                              _vm._v(" 项目区域"),
                            ]
                          ),
                          _vm.activeName == "projectArea"
                            ? _c("projectArea", { ref: "projectArea" })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.showTab || _vm.showTab == "1"
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "projectEAExitLog" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("i", { staticClass: "iconfont icon-rizhi" }),
                              _vm._v(" 项目进出场日志"),
                            ]
                          ),
                          _vm.activeName == "projectEAExitLog"
                            ? _c("projectEAExitLog", {
                                ref: "projectEAExitLog",
                                attrs: { prjName: _vm.dataForm.prjName },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataForm.prjType == 1
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "greenFactory" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-gongchang",
                              }),
                              _vm._v(" 绿色工厂"),
                            ]
                          ),
                          _vm.activeName == "greenFactory"
                            ? _c("greenFactory", {
                                ref: "greenFactory",
                                attrs: { prjName: _vm.dataForm.prjName },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataForm.prjType == 2
                    ? _c(
                        "el-tab-pane",
                        { attrs: { name: "greenMines" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-kuangshan",
                              }),
                              _vm._v(" 绿色矿山"),
                            ]
                          ),
                          _vm.activeName == "greenMines"
                            ? _c("greenMines", {
                                ref: "greenMines",
                                attrs: { prjName: _vm.dataForm.prjName },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "personnel_positioning" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "iconfont el-icon-film" }),
                        _vm._v(" 人员定位"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "gate" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "iconfont el-icon-film" }),
                        _vm._v(" 闸机管理"),
                      ]),
                      _vm.activeName == "gate"
                        ? _c("gate", {
                            ref: "gate",
                            attrs: {
                              prjName: _vm.dataForm.prjName,
                              prjId: _vm.dataForm.id,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "visitorReservation" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "iconfont el-icon-s-custom" }),
                        _vm._v(" 访客预约"),
                      ]),
                      _vm.activeName == "visitorReservation"
                        ? _c("visitorReservation", {
                            ref: "visitorReservation",
                            attrs: {
                              prjName: _vm.dataForm.prjName,
                              prjId: _vm.dataForm.id,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "visitorRecord" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "iconfont el-icon-s-custom" }),
                        _vm._v(" 访客记录"),
                      ]),
                      _vm.activeName == "visitorRecord"
                        ? _c("visitorRecord", {
                            ref: "visitorRecord",
                            attrs: {
                              prjName: _vm.dataForm.prjName,
                              prjId: _vm.dataForm.id,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("user-select", {
        ref: "userSelect",
        on: { callBack: _vm.handleUserSelectConfirm },
      }),
      _c("thirdPartyOrgQuote", {
        ref: "thirdPartyOrgQuote",
        on: { callback: _vm.getPrjthirdcompanyPage },
      }),
      _c("addThirduser", {
        ref: "addThirduser",
        on: { callback: _vm.getPrjthirduserPage },
      }),
      _c("addThirduserQuote", {
        ref: "addThirduserQuote",
        on: { callback: _vm.getPrjthirduserPage },
      }),
      _c("addCorePersonnelDialog", {
        ref: "addCorePersonnelDialog",
        attrs: { dataForm: _vm.dataForm },
        on: { changeOPData: _vm.handleUserSelectConfirm },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "相关方人员导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "import" },
            [
              _vm.excelBox
                ? _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }