var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog selectPeopleByCurrentPrjoect",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              searchTitle: _vm.searchOrTitle,
              treeData: _vm.organizationPersonnelTreeData,
              isShowdig: false,
              defaultProps: _vm.organizationPersonnelDefaultProps,
              showCheckbox: false,
              treeExpand: true,
              "node-key": "id",
            },
            on: { getNodeClick: _vm.organizationPersonnelTreeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "table-data": _vm.organizationPersonnelTableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "birthday",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.birthday && row.birthday != ""
                                  ? _vm.getAge(row.birthday)
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowSelect(row)
                              },
                            },
                          },
                          [_vm._v("选择\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }