<template>
  <el-dialog
    title="选择组织"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="60%"
    class="qmDialog"
    @close="closeDialog"
  >
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
      <template slot="birthday" slot-scope="{ row }">
        <span>
          {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
        </span>
      </template>
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowSelect(row)"
          >选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { getOrgListByPersonProject } from "@/api/siteManagement/collaborativeEAExit";
import { getAgeFromIdCard } from "@/util/util";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "公司名称",
          prop: "companyName",
          span: 4,
          placeholder: "公司名称",
        },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "公司名称",
            prop: "companyName",
            align: "center",
            overHidden: true,
          },
          {
            label: "成立日期",
            prop: "curDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "注册地",
            prop: "regLocation",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
      submitBtn: false,
      emptyBtn: false,
      query: {},
    };
  },
  methods: {
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    init(query) {
      this.query = query;
      this.showDialog = true;
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getOrgListByPersonProject(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择公司
    rowSelect(row) {
      this.showDialog = false;
      this.$emit("callback", row);
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
