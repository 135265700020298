<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="访客预约" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template slot="gateMachineVOList" slot-scope="{ row }">
          <div v-if="row.gateMachineVOList && row.gateMachineVOList.length">
            <span v-for="item in row.gateMachineVOList" :key="item.id">
              {{item.eqtCode}}</span>
          </div>
        </template>
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" v-if="row.visitStatus!='ALSO_AWAY'" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" v-if="row.visitStatus!='ALSO_AWAY'" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addVisitor ref="addVisitor" @callback="onLoad(page, {})"></addVisitor>
    <deviceAllSelect ref="deviceAllSelect" @callBack="saveDevice"></deviceAllSelect>

  </div>
</template>

<script>
  import {
    getList,
    getDetail,
    add,
    update,
    remove,
    saveSelect
  } from "@/api/safe/visitorReservation";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import addVisitor from "./addVisitor";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";
  import deviceAllSelect from "./device-all-select";


  export default {
    components: {
      addVisitor,
      GridLayout,
      HeadLayout,
      deviceAllSelect,
    },
    props: {
      prjId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          linklabel: "visitName",
          reserveSelection: true,
          column: [{
              label: "访客姓名",
              prop: "visitName",
              align: "center",
              overHidden: true,
            },
            {
              label: "证件类型",
              prop: "idType",
              align: "center",
              // dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqVisitAppointment_idType",
            },
            {
              label: "证件号码",
              prop: "idNumber",
              align: "center",
              overHidden: true,

            },
            {
              label: "联系方式",
              prop: "contactWay",
              align: "center",
              overHidden: true,
            },
            {
              label: "访客单位",
              prop: "visitCompany",
              align: "center",
              overHidden: true,
            },
            {
              label: "被访人",
              prop: "beVisitName",
              align: "center",
              overHidden: true,
            },
            {
              label: "访问事由",
              prop: "visitReason",
              align: "center",
              overHidden: true,
              // dataType: "number",
              // props: {
              //   label: "dictValue",
              //   value: "dictKey",
              // },
              // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqGateMachineStatus",
            },
            {
              label: "预计开始日期",
              prop: "planBeginTime",
              align: "center",
              overHidden: true,
            },
            {
              label: "预计结束日期",
              prop: "planEndTime",
              align: "center",
              overHidden: true,
            },
            {
              label: "设备编号",
              prop: "gateMachineVOList",
              align: "center",
              overHidden: true,
              slot: true,
            },
            {
              label: "访客状态",
              prop: "visitStatus",
              align: "center",
              overHidden: true,
              // dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqVisitAppointment_visitStatus",

            },
            {
              label: "备注",
              prop: "remarks",
              align: "center",
              overHidden: true,
            },
          ],
        },
        headBtnOptions: [{
            label: "设置权限",
            emit: "head-user",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          {
            label: "新增访客",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          // {
          //   label: "导入",
          //   emit: "head-import",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "import",
          // },
          // {
          //   label: "导出",
          //   emit: "head-export",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "export",
          // },
        ],
        searchColumns: [{
            label: "访客姓名",
            prop: "visitName",
            span: 4,
            placeholder: "请输入访客姓名",
          },
          {
            label: "通行状态",
            prop: "visitStatus",
            span: 4,
            placeholder: "请选择通行状态",
            type: "select",
            // dicData: [{
            //     value: 1,
            //     label: '是'
            //   },
            //   {
            //     value: 0,
            //     label: '否'
            //   },
            // ]
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqVisitAppointment_visitStatus",
          },
          {
            label: "通行时间",
            prop: "time",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            span: 4,
            type: 'daterange',
            startPlaceholder: "进入时间",
            endPlaceholder: "离开时间",
          },

        ],
        tableData: [],
        excelBox: false,
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          excelForm: {},
          column: [{
              label: "数据导入",
              prop: "excelFile",
              type: "upload",
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: "data",
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              action: "/api/sinoma-hse-prj/thirduser/import",
            },

            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: "excelTemplate",
              formslot: true,
              span: 24,
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },
    watch: {
      "excelForm.isCovered"() {
        if (this.excelForm.isCovered !== "") {
          const column = this.findObject(this.excelOption.column, "excelFile");
          column.action = `/api/sinoma-hse-prj/thirduser/import?isCovered=${this.excelForm.isCovered}`;
        }
      },
    },
    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addVisitor.init("add", null, this.prjId);
      },
      rowUpdate(row) {
        this.$refs.addVisitor.init("edit", row, this.prjId);
      },
      //查看
      rowView(row) {
        this.$refs.addVisitor.init("view", row, this.prjId);
      },
      saveDevice(data) {
        let that = this
        let newId = this.selectionList.map((item) => {
          return item.id
        })
        let newuser = data.map((item) => {
          return item.id
        })
        let query = {
          prjId: this.prjId,
          appointmentIdList: newId,
          machineIdList: newuser
        }
        saveSelect(query).then((res) => {
          this.selectionList = []
          this.$refs.gridLayOut.selectionClear();
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
      },
      // 多选
      selectionChange(list) {
        this.selectionList = list;
      },
      //人员设置
      handleUser() {
        if (this.selectionList.length) {
          this.$refs.deviceAllSelect.init(null, this.prjId, this.selectionList)
        } else {
          this.$message.warning("请选择至少一条数据");
        }

      },
      getAge(data) {
        return getAgeFromIdCard(data);
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.gridLayOut.selectionClear();
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        if (searchForm.time) {
          searchForm.planBeginTime = searchForm.time[0] + ' 00:00:00';
          searchForm.planEndTime = searchForm.time[1] + ' 23:59:59';
        } else {
          searchForm.planBeginTime = ''
          searchForm.planEndTime = ''
        }

        // delete searchForm.time
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.prjId = this.prjId
        getList(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },
      // 导入
      handleImport() {
        this.excelBox = true;
        this.excelOption.excelForm.excelFile = [];
      },
      uploadAfter(res, done, loading, column) {
        window.console.log(column);
        this.excelBox = false;
        this.onLoad(this.page);
        this.excelOption.excelForm.excelFile = [];
        done();
      },
      handleTemplate() {
        exportBlob(
          `/api/sinoma-hse-prj/thirduser/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, "相关方人员模板" + ".xlsx");
        });
      },
      // 导出
      handleExport() {
        this.$confirm("是否导出相关方人员数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          exportBlob(
            `/api/sinoma-hse-prj/thirduser/export?userName=${
            this.$refs.gridHeadLayout.searchForm.userName
              ? this.$refs.gridHeadLayout.searchForm.userName
              : ""
          }&isUsed=${
            this.$refs.gridHeadLayout.searchForm.isUsed
              ? this.$refs.gridHeadLayout.searchForm.isUsed
              : ""
          }&updateTime=${
            this.$refs.gridHeadLayout.searchForm.updateTime
              ? this.$refs.gridHeadLayout.searchForm.updateTime
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
          ).then((res) => {
            downloadXls(res.data, `相关方人员${dateNow()}.xlsx`);
          });
        });
      },
    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
