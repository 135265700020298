<template>
  <div>
    <div v-show="isShowList">
      <head-layout
        head-title="绿色工厂"
        :showIcon="false"
        :head-btn-options="headBtnOptions"
        @head-add="headAdd"
      />
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <!--  -->
      <grid-layout
        class="itemListGrid"
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @gird-handle-select-click="selectionChange"
        :grid-row-btn="gridRowBtn"
        @row-edit="rowEdit"
        @row-remove="rowRemove"
      >
      </grid-layout>
    </div>
    <div v-show="!isShowList">
      <greenFactoryForm v-if="!isShowList" ref="greenFactoryForm" @callback="callback" :prjId="prjId" :prjName="prjName" :contractId="contractId" :detailId="detailId"></greenFactoryForm>
    </div>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import greenFactoryForm from "./greenFactoryForm.vue";
import {
  getAreaClassPage,
  getAreaClassLinRemove,
} from "@/api/safe/projectArea";
import { greenFactoryPage, greenFactoryRemove } from "@/api/safe/greenFactory";
export default {
  components: {
    HeadLayout,
    GridLayout,
    greenFactoryForm,
  },
  props: {
    prjName: {
      type: "String",
    },
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "申报日期",
          prop: "startDate",
          span: 4,
          type: "date-format",
          placeholder: "请选择申报日期",
        },
      ],
      isShowList: true,
      formType: "",
      contractId: "",
      query:{},
      detailId: ""
    };
  },
  computed: {
    headBtnOptions() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add",
            type: "button",
            icon: "",
            btnOptType: "add",
          },
        ];
      }
    },

    tableOptions() {
      return {
        menu: this.$route.query.type != "view",
        selection: false,
        column: [
          {
            label: "申报日期",
            prop: "startDate",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "评选级别",
            prop: "selectionLevel",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=selection_level`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "有效期",
            prop: "endDate",
            align: "center",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "statusLabel",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: "",
          },
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: "",
          },
        ];
      }
    },
  },
  mounted(){
    let type = this.$route.query.type;
    this.formType = type;
  },
  methods: {
    compareDates(specifiedDateString) {
      const currentDate = new Date();
      const specifiedDate = new Date(specifiedDateString);
      if (currentDate > specifiedDate) {
        return "过期";
      } else if (currentDate < specifiedDate) {
        return "正常";
      } else {
        return "正常";
      }
    },
    init(prjId, contractId) {
      this.isShowList = true
      this.prjId = prjId;
      this.query.prjId = this.prjId
      this.contractId = contractId
      this.onLoad(this.page);
    },
    // 筛选列表
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;

      greenFactoryPage(
        page.currentPage,
        page.pageSize,
        Object.assign(page.currentPage, page.pageSize, params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records.map(item=>{
          return{
            ...item,
            statusLabel: this.compareDates(item.endDate)
          }
        });
        console.log('table',this.tableData)
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    rowEdit(row, index) {
      this.isShowList = false;
      this.detailId = row.id
    },
    // 单条删除
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        greenFactoryRemove(row.id).then(() => {
          this.$t("cip.cmn.msg.success.operateSuccess");
          this.onLoad(this.page);
        });
      });
    },
    headAdd() {
      this.detailId = ""
      this.isShowList = false;
    },
    callback(){
      this.onLoad(this.page)
      this.isShowList = true
    }
  },
};
</script>
<style scoped lang="scss"></style>
