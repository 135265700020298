var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "el-tree-select", class: _vm.selectClass },
    [
      _c(
        "el-select",
        _vm._b(
          {
            directives: [
              { name: "popover", rawName: "v-popover:popover", arg: "popover" },
            ],
            ref: "select",
            staticClass: "el-tree-select-input",
            style: _vm.styles,
            attrs: {
              id: "el-tree-select-" + _vm.guid,
              disabled: _vm.disabled,
              "popper-class": "select-option",
              "popper-append-to-body": false,
              filterable: false,
              multiple: _vm.selectParams.multiple,
              title: _vm.labels,
            },
            on: {
              "remove-tag": _vm._selectRemoveTag,
              clear: _vm._selectClearFun,
              focus: _vm._popoverShowFun,
            },
            model: {
              value: _vm.labels,
              callback: function ($$v) {
                _vm.labels = $$v
              },
              expression: "labels",
            },
          },
          "el-select",
          _vm.selectParams,
          false
        )
      ),
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: {
            placement: _vm.placement,
            transition: _vm.transition,
            "popper-class": _vm.popperClass,
            width: _vm.width,
            trigger: "click",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _vm.treeParams.filterable
            ? _c(
                "el-input",
                {
                  staticClass: "input-with-select mb10",
                  attrs: { size: "mini" },
                  on: { change: _vm._searchFun },
                  model: {
                    value: _vm.keywords,
                    callback: function ($$v) {
                      _vm.keywords = $$v
                    },
                    expression: "keywords",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-scrollbar",
            {
              staticClass: "is-empty",
              attrs: {
                tag: "div",
                "wrap-class": "el-select-dropdown__wrap",
                "view-class": "el-select-dropdown__list",
              },
            },
            [
              _c(
                "el-tree",
                _vm._b(
                  {
                    ref: "tree",
                    attrs: {
                      data: _vm.data,
                      "node-key": _vm.propsValue,
                      draggable: false,
                      "current-node-key": _vm.ids.length > 0 ? _vm.ids[0] : "",
                      "show-checkbox": _vm.selectParams.multiple,
                      "filter-node-method": _vm.filterNodeMethod
                        ? _vm.filterNodeMethod
                        : _vm._filterFun,
                      "render-content": _vm.treeRenderFun,
                    },
                    on: {
                      "node-click": _vm._treeNodeClickFun,
                      check: _vm._treeCheckFun,
                    },
                  },
                  "el-tree",
                  _vm.treeParams,
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }