<template>
  <el-dialog
    title="选择位置"
    append-to-body
    v-if="mapBox"
    :visible.sync="mapBox"
    width="555px">
    <div class="app-container">
      <el-autocomplete v-model="mapLocation.address" :fetch-suggestions="querySearch"
                       :placeholder="$t(`cip.plat.app.attendance.msg.address`)" style="width: 100%" :trigger-on-focus="false"
                       @select="handleSelect" />
      <div style="margin: 5px">
        <Map class="bm-view" :center="mapCenter" :zoom="mapZoom" :scroll-wheel-zoom="true" ak="baidu-ak"
                   @ready="handlerBMap" @click="clickEvent" />
      </div>
    </div>
    <span slot="footer" class="avue-dialog__footer">
        <el-button size="small" @click="mapBox = false">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
  </el-dialog>

</template>

<script>
import Map from 'vue-baidu-map/components/map/Map.vue'
export default {
  name: 'BaiduMap',
  components: {
    Map
  },
  model:{
    event:'change',
    prop:'mapData'
  },
  props: {
    // config参数配置事例
    mapData:{
      type: Object,
      default: () => {}
    },
    point: {
      type: String,
      default: ''
    }
  },
  created() {

  },
  data() {
    return {
      mapBox:false,
      mapCenter:{ lng: 0, lat: 0 },
      mapZoom: 15,
      mapLocation: {
        address: undefined,
        coordinate: undefined
      },
    }
  },
  methods: {
    initMap(){
      this.mapBox=true
      if (this.point) {
        this.getAddress()
      } else {
        this.getLocation();
      }
    },
    //根据经纬度获取位置
    getAddress() {
      let that = this
      let pointArr = this.point.split(',')
      const point = new BMap.Point(pointArr[0], pointArr[1]);
      const gc = new BMap.Geocoder()
      gc.getLocation(point, function (rs) {
        that.mapCenter.lat = rs.point.lat;
        that.mapCenter.lng = rs.point.lng;
        that.mapLocation.address = rs.addressComponents.city;
        that.$emit("getLocalAddress", that.mapCenter.lat, that.mapCenter.lng)
      })
    },
    getLocation() {
      let that = this
      that.mapCenter.lat = 40.01681;
      that.mapCenter.lng = 116.481752;
      that.mapLocation.address = '北京市';
      this.mapLocation.coordinate = { lng: 116.481752, lat: 40.01681}
      that.$emit("getLocalAddress", that.mapCenter.lat, that.mapCenter.lng)
    },
    handlerBMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
        this.mapCenter.lng = this.mapLocation.coordinate.lng
        this.mapCenter.lat = this.mapLocation.coordinate.lat
        this.mapZoom = 15
        map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate))
      } else {
        this.mapCenter.lng = this.mapCenter.lat
        this.mapCenter.lat = this.mapCenter.lng
        this.mapZoom = 10
      }
    },
    //点击地图获取经纬度
    clickEvent(e) {
      this.mapCenter.lat = e.point.lat;
      this.mapCenter.lng = e.point.lng;
      this.$emit("clickEvent", e.point.lng, e.point.lat)
    },
    querySearch(queryString, cb) {
      var that = this
      var myGeo = new this.BMap.Geocoder()
      myGeo.getPoint(queryString, function (point) {
        if (point) {
          that.mapLocation.coordinate = point
          that.makerCenter(point)
        } else {
          that.mapLocation.coordinate = null
        }
      }, this.locationCity)
      var options = {
        onSearchComplete: function (results) {
          if (local.getStatus() === 0) {
            // 判断状态是否正确
            var s = []
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              var x = results.getPoi(i)
              var item = { value: x.address, point: x.point }
              s.push(item)
              cb(s)
            }
          } else {
            cb()
          }
        }
      }
      var local = new this.BMap.LocalSearch(this.map, options)
      local.search(queryString)
    },
    handleSelect(item) {
      var { point } = item
      this.mapLocation.coordinate = point
      this.makerCenter(point)
      this.$emit("changePosition", point.lat, point.lng)
    },
    makerCenter(point) {
      if (this.map) {
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
        this.mapCenter.lng = point.lng
        this.mapCenter.lat = point.lat
        this.mapZoom = 15
      }
    },
    handleMapSave(){
      this.mapData.longitude=this.mapCenter.lng
      this.mapData.latitude=this.mapCenter.lat
      this.mapBox=false;
      this.$emit('change',this.mapData)
    },
  }
}
</script>

<style>
.bm-view {
  width: 100%;
  height: 550px;
}

/*::v-deep .bm-view a {*/
/*  display: none;*/
/*}*/

.BMap_cpyCtrl,
.anchorBL {
  display: none;
}
</style>
