var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowList,
            expression: "isShowList",
          },
        ],
      },
      [
        _c("head-layout", {
          attrs: {
            "head-title": "绿色工厂",
            showIcon: false,
            "head-btn-options": _vm.headBtnOptions,
          },
          on: { "head-add": _vm.headAdd },
        }),
        _c("grid-head-layout", {
          ref: "gridHeadLayout",
          attrs: { "search-columns": _vm.searchColumns },
          on: {
            "grid-head-search": _vm.gridHeadSearch,
            "grid-head-empty": _vm.gridHeadEmpty,
          },
        }),
        _c("grid-layout", {
          ref: "gridLayOut",
          staticClass: "itemListGrid",
          attrs: {
            "table-options": _vm.tableOptions,
            "table-data": _vm.tableData,
            "table-loading": _vm.tableLoading,
            "data-total": _vm.page.total,
            page: _vm.page,
            "grid-row-btn": _vm.gridRowBtn,
          },
          on: {
            "page-current-change": _vm.handleCurrentChange,
            "page-size-change": _vm.handleSizeChange,
            "page-refresh-change": _vm.onLoad,
            "gird-handle-select-click": _vm.selectionChange,
            "row-edit": _vm.rowEdit,
            "row-remove": _vm.rowRemove,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isShowList,
            expression: "!isShowList",
          },
        ],
      },
      [
        !_vm.isShowList
          ? _c("greenFactoryForm", {
              ref: "greenFactoryForm",
              attrs: {
                prjId: _vm.prjId,
                prjName: _vm.prjName,
                contractId: _vm.contractId,
                detailId: _vm.detailId,
              },
              on: { callback: _vm.callback },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }