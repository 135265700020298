var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mapBox
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": "",
            visible: _vm.mapBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c("el-autocomplete", {
                staticStyle: { width: "100%" },
                attrs: {
                  "fetch-suggestions": _vm.querySearch,
                  placeholder: _vm.$t(`cip.plat.app.attendance.msg.address`),
                  "trigger-on-focus": false,
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.mapLocation.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.mapLocation, "address", $$v)
                  },
                  expression: "mapLocation.address",
                },
              }),
              _c(
                "div",
                { staticStyle: { margin: "5px" } },
                [
                  _c("Map", {
                    staticClass: "bm-view",
                    attrs: {
                      center: _vm.mapCenter,
                      zoom: _vm.mapZoom,
                      "scroll-wheel-zoom": true,
                      ak: "baidu-ak",
                    },
                    on: { ready: _vm.handlerBMap, click: _vm.clickEvent },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "avue-dialog__footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.mapBox = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }