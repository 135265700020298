<template>
  <el-dialog title="相关方组织选择" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog"
    width="60%" class="qmDialog thirdPartyOrgQuote" @close="closeDialog">
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"></grid-head-layout>

    <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
      :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
      <template slot="birthday" slot-scope="{ row }">
        <span>
          {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
        </span>
      </template>
      <template #customBtn="{ row }">
        <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";

import { getList } from "@/api/safe/thirdcompany";
import { prjthirdcompanySubmit } from "@/api/safe/itemList";
import { getAgeFromIdCard } from "@/util/util";
import { fieldInfo } from "@/api/base/length.js"
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "项目名称",
          prop: "companyCode",
          span: 6,
          placeholder: "公司名称或统一社会信用代码",
        },
        // {
        //   label: "公司状态",
        //   prop: "isUsed",
        //   span: 4,
        //   type: "select",
        //   placeholder: "请选择公司状态",
        //   dataType: "number",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
        // },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            minWidth: fieldInfo.USCI.length,
            align: fieldInfo.USCI.align,
            overHidden: true
          },
          {
            label: "公司名称",
            prop: "companyName",
            minWidth: fieldInfo.name_company.length,
            align: fieldInfo.name_company.align,
            overHidden: true
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            minWidth: fieldInfo.name_person.length,
            align: fieldInfo.name_person.align,
            overHidden: true
          },
          {
            label: "注册详细位置",
            prop: "regLocation",
            minWidth: fieldInfo.name_regionAll.length,
            align: fieldInfo.name_regionAll.align,
            overHidden: true
          },
          {
            label: "成立日期",
            prop: "curDate",
            minWidth: fieldInfo.date.length,
            align: fieldInfo.date.align,
            overHidden: true
          },
          // {
          //   label: "启用",
          //   prop: "isUsed",
          //   dataType: "number",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          //   align: "center",
          // },
        ],
      },
      tableData: [],
      submitBtn: false,
      emptyBtn: false,
      priId: "",

    };
  },
  methods: {
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    init(prjId) {
      this.prjId = prjId;
      this.showDialog = true;
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // params.excludeRrjId = this.prjId
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择公司
    rowSelect(row) {
      this.showDialog = false;
      let params = {};
      params.companyName = row.companyName;
      params.companyCode = row.companyCode;
      params.areaId = row.areaId;
      params.legalRep = row.legalRep;
      params.curDate = row.curDate;
      params.regLocation = row.regLocation;

      params.isUsed = row.isUsed;
      params.prjId = this.prjId;


      prjthirdcompanySubmit(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
          this.$emit("callback");
        }
      });
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.thirdPartyOrgQuote .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
